.content {
    margin-top: 100px;
    margin-bottom: 10px;
}
html {
    min-height: 100vh;
    background: #f5f5f5;
}
body {
    background-color: transparent !important;
}
.right-10 {
    right: 10px;
}
a {
    font-weight: bold !important;
    text-transform: none !important;
    text-decoration: none !important;
}
a:hover {
    color: #ec6730 !important;
    opacity: 0.8 !important;
}
tr {
    cursor: pointer;
}
.btn.btn-gastro {
    color: #fff;
    background-color: #EC6730;
    border-color: #EC6730;
}
.btn.btn-gastro:hover {
    color: #fff;
    background-color: #f38253;
    border-color: #f38253;
}
.css-1nrlq1o-MuiFormControl-root {
    vertical-align: baseline !important;
}
.MuiPaper-root * {
    font-family: inherit !important;
    margin-bottom: 0 !important;
}

.MuiPaper-root .MuiTableFooter-root {
    margin-bottom: 0 !important;
}
.formPage .MuiInputBase-root,
.formPage .MuiFormControl-root {
    width: 75%;
}

.customPagination a {
    color:  #EC6730;
}
.page-item.active a {
    background-color: #EC6730;
    color: #FFFFFF;
    border-color: #EC673050;
}
.page-item.active a:hover {
    background-color: #EC673050;
    color: #FFFFFF;
    border-color: #EC673050;
}
.modal {
    z-index: 9999 !important;
}
.my-color {
    color: #EC6730;
}

.image-content{
    position: relative;
}
.image-content img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
}
.avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
}
.fake-link {
    cursor: pointer;
    color: #EC6730;
    font-weight: bold;
}
.fake-link:hover {
    opacity: 0.8;
}
.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
tr.click-disabled {
    background-color: #efefef;
    cursor: inherit;
}
