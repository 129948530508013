.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    width: 443px;
    height: 351px;
    border-radius: none;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    color: #817F7C;
  }
  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modal-main .footer1 button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color:#F03738;
    color: white;
    border-radius: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  #cancelBtn2{
    background-color:  #FAF8F6;
    color: #817F7C;
    margin-left: 70px;
    font-size: 15px;

  }
  
  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .modal-main1 {
    position: fixed;
    background: white;
    width: 460px;
    height: 350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 250%;
    background: rgba(0, 0, 0, 0.6);
  }

  .App .openModalBtn1 {
    width: 200px;
    height: 40px;
    border: none;
    border-radius: none;
    background-color: #FAF8F6;
    color: white;
    cursor: pointer;
    margin-left: 20px;
  }

