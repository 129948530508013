
  .block-m4 {
    position: absolute;
    background: white;
    width:40%;
    Height: 70%;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 5%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .t1{
    font-size: 20px;
    color: black;
    font-weight:600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-left: 35%;
    margin-top: -5%;
  }

  .block-m4 .footer button {
    width: 258px;
    height: 42px;
    margin-left: 2%;
    margin-top: 15%;
    border: none;
    background-color:#23B64C;
    color: white;
    border-radius: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  #cancelBtn3 {
    width: 258px;
    height: 42px;
    background-color: #FAF8F6;
    color: #817F7C;
    margin-left: 3%;

  }
  
